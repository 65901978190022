<template>
  <div id="payment">

    <banner v-if="!failed" />
    <bannerFailed v-if="failed" />

    <steps v-if="!failed" />
    <stepsFailed v-if="failed" />
  </div>
</template>

<script>
  export default {
    name: 'Payment',

    components: {
      Banner: () => import('@/components/payment/Banner'),
      Steps: () => import('@/components/payment/Steps'),
      BannerFailed: () => import('@/components/payment/failedBanner'),
      StepsFailed: () => import('@/components/payment/failedSteps'),
    },
    data() {
      return {
        failed: false
      };
    },
    mounted() {
      this.failed = this.$route.query.failed;
    }
  }
</script>
