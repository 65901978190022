<template>
  <div id="home">

    <banner />

    <steps />
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      Banner: () => import('@/components/404/Banner'),
      Steps: () => import('@/components/404/Steps'),
    },
  }
</script>
