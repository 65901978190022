<template>
  <div id="home">

    <banner />

    <steps />
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      Banner: () => import('@/components/home/Banner'),
      Steps: () => import('@/components/home/Steps'),
    }
  }
  // dataLayer.push({
  //   'event':'VirtualPageView',
  //   'virtualPageURL':'/vpv/home',
  //   'virtualPageTitle' : 'Make a payment | Paymentshield'
  // });
  dataLayer.push({
    event: 'pageview',
    page: {
      path: '/vpv/home',
      title: 'Make a payment | Paymentshield'
    }
  });
</script>
