<template>
  <v-app>
    <core-app-bar />

    <core-view />

    <core-top-footer />
    <core-footer />

  </v-app>
</template>

<script>
  export default {
    name: 'App',

    components: {
      CoreTopFooter: () => import('@/components/core/TopFooter'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreView: () => import('@/components/core/View'),
    },
    watch: {
      '$route':{
        handler: (to, from) => {
          document.title = to.meta.title || 'Your Website'
        },
         immediate: true
      }
    }
  }
</script>
