import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import style from './assets/css/style.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

var env = window.location.origin

if (env.includes("test1")) {
  axios.defaults.baseURL = 'https://st1-api.paymentshield.co.uk/Policy' //TEST 1 (HTTPS)
}

else if (env.includes("test2")) {
  axios.defaults.baseURL = 'http://st2-api.paymentshield.co.uk/Policy' //TEST 2
}

else if (env.includes("test3")) {
  axios.defaults.baseURL = 'http://st3-api.paymentshield.co.uk/Policy' //TEST 3
}

else if (env.includes("https://secure.paymentshield.co.uk")) {
  axios.defaults.baseURL = 'https://api.paymentshield.co.uk/Policy' //LIVE
}

else {
  axios.defaults.baseURL = 'https://apiuat.paymentshield.co.uk/Policy' //UAT
}

import moment from 'moment';

Vue.prototype.moment = moment

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

new Vue({
  vuetify,
  router,
  store,
  style,
  axios,
  VueAxios,
  render: h => h(App),
}).$mount('#app')
