import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Payment from '../views/Payment.vue'
import FourOFour from '../views/404.vue'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
        title: 'Make a payment | Paymentshield'
      }
    },
    {
      path: '/payment',
      name: 'payment',
      component: Payment,
      meta: {
        auth: true,
        title: 'Thank you | Paymentshield'
      }
    },
    {
      path: '/404',
      name: '404',
      component: FourOFour,
      meta: {
        auth: true,
        title: 'Page not Found | Paymentshield'
      }
    }
  ],
})
